import { Service } from './service'

const PATH = 'certified-email'

class CertifiedEmailService extends Service {
  getData (params = {}) {
    return this.get(PATH, params)
  }

  existFile (urlParams) {
    return this.get(PATH + '/pdf-exists/' + urlParams)
  }
}

export default new CertifiedEmailService()
